
// @ts-nocheck


export const localeCodes =  [
  "ru",
  "en"
]

export const localeLoaders = {
  ru: [
    {
      key: "locale_ru_45RU_46js_f32890e2",
      load: () => import("#nuxt-i18n/f32890e2" /* webpackChunkName: "locale_ru_45RU_46js_f32890e2" */),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_45US_46js_92b78305",
      load: () => import("#nuxt-i18n/92b78305" /* webpackChunkName: "locale_en_45US_46js_92b78305" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "ru",
      iso: "en-US",
      name: "Русский",
      files: [
        "/var/www/www-root/data/www/app/i18n/locales/ru-RU.js"
      ]
    },
    {
      code: "en",
      iso: "ru-RU",
      name: "English",
      files: [
        "/var/www/www-root/data/www/app/i18n/locales/en-US.js"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "https://test.geonomix.app",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  legacy: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "ru",
    iso: "en-US",
    name: "Русский",
    files: [
      {
        path: "/var/www/www-root/data/www/app/i18n/locales/ru-RU.js",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    iso: "ru-RU",
    name: "English",
    files: [
      {
        path: "/var/www/www-root/data/www/app/i18n/locales/en-US.js",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/