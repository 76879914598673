import _ from "lodash"
import { uuid } from 'vue-uuid'
import { useNuxtApp } from 'nuxt/app'

export const engineering = {
    namespaced: true,
    state: () => ({
        measurementSystems: [
            {
                id: 'metric',
                name: 'measurement.metric.title',
                isActive: true
            },
            {
                id: 'british',
                name: 'measurement.british.title',
                isActive: false
            }
        ],
        options: {
            caseData: {},
            caseTypes: [
                {
                    id: 'oil',
                    name: 'Нефть',
                    isActive: false
                },
                {
                    id: 'gas',
                    name: 'Газ',
                    isActive: false
                }
            ],
            resultIterationsCount: [
                {
                    name: 'fix', id: '1', isActive: false
                },
                {
                    name: '1000', id: '1000', isActive: false
                },
                {
                    name: '2000', id: '2000', isActive: false
                },
                {
                    name: '5000', id: '5000', isActive: false
                }
            ],
            wellModelType: false,
            progressJob: {
                id: null,
                type: null
            }
        },
        caseSettings: null,
        defaultProps: null,
        originalDefaultProps: null,
        wellTypes: [],
        chartsStore: {},
        selectedCase: null,
        wellAnalogs: [],
        WC: {
            cases: [],
            models: []
        },
        matBalance: [],
        result: {
            object: {},
            tuning: {}
        },
        triggerSetManualWellQuantity: false,
        triggerResultWell: false,
        triggerResultObject: false,
        triggerResultDistribution: false,
        triggerOpenResultDistributionWindow: false,
        manualDensity: false
    }),
    mutations: {
        setMeasureSystem(state, payload) {
            const id = payload
            state.measurementSystems.forEach((system) => {
                if (system.id === id) {
                    system.isActive = true
                } else {
                    system.isActive = false
                }
            })
        },
        updateResultIterationsCount(state, payload) {
            const count = payload
            state.options.resultIterationsCount.forEach(element => {
                if (Number(element.id) === Number(count)) {
                    element.isActive = true
                } else {
                    element.isActive = false
                }
            })
            state.caseSettings.result_quantity = Number(count)
        },
        openResultWindowDistribution(state) {
            state.triggerOpenResultDistributionWindow = !state.triggerOpenResultDistributionWindow
        },
        saveResult(state, payload) {
            const { result, uuid, props, path } = payload

            _.set(state.result[path],  `${uuid}`, { ...result, props: props })
        },
        triggerResult(state, payload) {
            if (payload === 'well') state.triggerResultWell = !state.triggerResultWell
            if (payload === 'object') state.triggerResultObject = !state.triggerResultObject
            if (payload === 'distribution') state.triggerResultDistribution = !state.triggerResultDistribution
        },
        setCaseData(state, payload) {
            payload.caseId ? state.options.caseData.caseId = payload.caseId : false
            payload.projectId ? state.options.caseData.projectId = payload.projectId : false
        },
        setCaseType(state, payload) {
            state.options.caseTypes.map(item => item.id === payload ? item.isActive = true : item.isActive = false)
        },
        saveChartData(state, payload) {
            const [wellTypeUuid, chartData, caseData, model, jobId] = payload

            chartData ? _.set(state.chartsStore,  `${wellTypeUuid}.${model}.chart`, chartData) : false
            caseData ? _.set(state.chartsStore,  `${wellTypeUuid}.${model}.case`, caseData) : false
            jobId ? _.set(state.chartsStore,  `${wellTypeUuid}.${model}.job_id`, jobId) : false
        },
        saveSelectedCase(state, payload) {
            state.selectedCase = payload
        },
        setManualDensity(state, payload) {
            state.manualDensity = payload
        },
        setManualWellQuantity(state, payload) {
            if (Object.keys(payload.total).length > 1) {
                state.caseSettings.wells_total.params = {
                    p10: payload.total.p10,
                    p50: payload.total.p50,
                    p90: payload.total.p90
                }
                state.caseSettings.wells_total.crop = {
                    min: payload.total.min,
                    max: payload.total.max
                }
                state.caseSettings.wells_total.distribution = 'meyerson'
                state.caseSettings.wells_total.type_param = 'distribution'
            } else {
                state.caseSettings.wells_total.params = {}
                state.caseSettings.wells_total.crop = {}
                state.caseSettings.wells_total.value = payload.total.value
                state.caseSettings.wells_total.type_param = 'fixed'
            }
            state.caseSettings.type_calculation_wells_total = 'default'
            state.triggerSetManualWellQuantity = !state.triggerSetManualWellQuantity
        },
        changeWellModelType(state, payload) {
            state.options.wellModelType = payload
        },
        setProgressJob(state, payload) {
            state.options.progressJob.id = payload.id
            state.options.progressJob.type = payload.type
        },
        addWellType(state, data) {
            const defultPropsKeys = Object.keys({...state.defaultProps.complex, ...state.defaultProps.simple})
            const activeWellTypeUuid = state.caseSettings.active_well_type_uuid
            const density = state.wellTypes.find(item => item.uuid == activeWellTypeUuid).data.density
            let copy = _.cloneDeep(state.wellTypes[state.wellTypes.length - 1])
            for (let key in copy.data) {
                if (defultPropsKeys.includes(key)) {
                    state.defaultProps.complex[key] ? copy.data[key] = state.defaultProps.complex[key].default_value : copy.data[key] = state.defaultProps.simple[key].default_value
                    if (key === 'density') {
                        copy.data[key] = density
                    }
                }
            }
            copy.name = data.name
            copy.description = data.description
            copy.id = null
            copy.uuid = uuid.v4()
            state.wellTypes.push(copy)
            state.caseSettings.active_well_type_uuid = copy.uuid
        },
        setActiveWellTab(state, payload) {
            state.caseSettings.active_well_type_uuid = payload
        },
        saveWellsTable(state, payload) {
            state.caseSettings.wells_table = payload
        },
        setMatBalanceItem(state, payload) {
            const { wellData, index } = payload

            if (index !== undefined && index !== null) {
                state.matBalance[index] = {
                    ...state.matBalance[index],
                    ...wellData
                }
            } else {
                state.matBalance.push({
                    ...wellData,
                    isActive: true,
                    settings: {
                        min: Number(state.caseSettings.start_year) - 100,
                        max: Number(state.caseSettings.start_year) + 100,
                        step: 1
                    },
                    year: Number(state.caseSettings.start_year),
                    color: '#FF9E5C',
                    openSettings: false,
                    topPosition: null
                })
            }
        },
        resetCaseResult(state) {
            state.result = {
                object: {},
                tuning: {}
            }
            state.chartsStore = {}
        }
    },
    actions: {
        async deleteWellTypeFromCase({ state }, payload) {
            if (state.wellTypes.length === 1) {
                return false
            } else {
                let index = state.wellTypes.findIndex(i => i.uuid == payload)
                state.wellTypes.splice(index, 1)
                state.caseSettings.active_well_type_uuid = state.wellTypes[0].uuid
                return true
            }
        },
        async getDefaultProps({ state }, payload) {
            const defaultProps = await $fetch(`/api/projects/${state.options.caseData.projectId}/engineercases/props_settings/${payload}`)
            state.defaultProps = defaultProps
            state.originalDefaultProps = _.cloneDeep(state.defaultProps)
        },
        async getCase({ state } , payload) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/engineercases/${state.options.caseData.caseId}`)

            if (error.value) return {
                error: true,
                errorMessage: error.value.data.message
            }

            const caseData = data.value

            await this.dispatch('engineering/getDefaultProps', caseData.type)

            state.wellTypes = caseData.well_types
            state.caseSettings = caseData.data
            state.options.caseTypes.forEach(type => {
                type.id == caseData.type ? type.isActive = true : type.isActive = false
            })
            this.commit('engineering/setCaseType', caseData.type)

            this.commit("common/setEngineeringCaseOptions", {
                name: caseData.name,
                id: caseData.id
            })

            this.commit('engineering/updateResultIterationsCount', state.caseSettings.result_quantity)

            // check if we have actual job in well model
            state.wellTypes.forEach(element => {
                if (element.actual_job) {
                    const data = _.omit(element.actual_job.data, ['case_type'])
                    this.commit('engineering/saveChartData', [element.uuid, element.actual_job.result, _.cloneDeep(data), 'well_model', element.actual_job.id])
                }
            })

            // check if we have actual job in object model
            if (caseData.actual_job) {
                const data = _.omit({...caseData.actual_job.data.case, ...caseData.well_types.find(type => type.uuid === caseData.data.active_well_type_uuid).data}, ['geo_case_id', 'geo_case_type', 'case_type'])
                const wellQuantityIsTable = data.type_calculation_wells_total === 'table' ? true : false

                if (wellQuantityIsTable) {
                    state.wellTypes.forEach(type => {
                        this.commit('engineering/saveChartData', [type.uuid, caseData.actual_job.result.object, data, 'object_model', caseData.actual_job.id])
                    })
                } else {
                    this.commit('engineering/saveChartData', [caseData.data.active_well_type_uuid, caseData.actual_job.result.object, data, 'object_model', caseData.actual_job.id])
                }

                this.commit('engineering/saveResult', {
                    uuid: caseData.actual_job.data.case.active_well_type_uuid,
                    result: caseData.actual_job.result.object.total,
                    props: caseData.actual_job.result.props,
                    path: 'object'
                })
            }

            // check if we have actual job in tuning model
            if (caseData.actual_job && caseData.actual_tuning_job) {
                const data = _.omit({...caseData.actual_tuning_job.data.case, ...caseData.actual_tuning_job.data.wells[[caseData.data.active_well_type_uuid]]}, ['geo_case_id', 'geo_case_type', 'case_type'])
                this.commit('engineering/saveChartData', [caseData.data.active_well_type_uuid, caseData.actual_tuning_job.result, data, 'tuning', caseData.actual_tuning_job.id])
                this.commit('engineering/saveResult', {
                    uuid: caseData.data.active_well_type_uuid,
                    result: caseData.actual_tuning_job.result.total,
                    props: caseData.actual_tuning_job.result.props,
                    path: 'tuning'
                })
            }

            // check if we have job in progress
            if (caseData.progress_job) {
                const jobPerformer = caseData.progress_job.performer.endsWith('CalcWell') ? 'well' : caseData.progress_job.performer.endsWith('CalcCase') ? 'object' : 'tuning'
                if (jobPerformer === 'object' || jobPerformer === 'tuning') this.commit('engineering/changeWellModelType', true)
                if (jobPerformer === 'tuning') {
                    this.commit('engineering/saveChartData', [caseData.data.active_well_type_uuid, caseData.progress_job.case_job_result, caseData.progress_job.data, 'object_model'])
                }

                this.commit('engineering/setProgressJob', {
                    id: caseData.progress_job.id,
                    type: jobPerformer
                })
            }


            if (caseData.geo_case_id) {
                const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/engineercases/get_geo_case_data`, {
                    method: 'POST',
                    body: {
                        id: caseData.geo_case_id,
                        type: `VseProsto\\GeoFormula\\Models\\${caseData.geo_case_type.endsWith('ObjectCase') ? 'Object' : 'Layer'}Case`
                    }
                })
            
                if (error.value) {
                    const errorMessage = error.value.data
            
                    this.commit('common/setErrorData', {
                        statusCode: error.value.statusCode,
                        message: errorMessage.message,
                        showError: true
                    })
            
                    this.commit("common/setLoadingStatus", false)
            
                    return
                }

                this.commit('engineering/saveSelectedCase', {
                    info: {
                        id: caseData.geo_case_id,
                        fullTitle: `${caseData.geo_case.object !== undefined ? caseData.geo_case.object.name : caseData.geo_case.layer.name}, ${caseData.geo_case.name}`,
                        model: caseData.geo_case_type,
                        modelId: caseData.geo_case.object !== undefined ? caseData.geo_case.object.id : caseData.geo_case.layer.id
                    },
                    props: Object.keys(data.value)
                        .filter((key) => ['rr_case', 'area'].includes(key))
                        .reduce((cur, key) => {
                            return Object.assign(cur, {
                                [key]: data.value[key]
                            }
                        )}, {})
                })
            }

            // check well analogs
            if (caseData.dca) {
                state.wellAnalogs = caseData.dca.map(analog => {
                    return {
                        isActive: analog.active,
                        settings: {
                            min: -100,
                            max: 100,
                            step: 1
                        },
                        year: analog.offset,
                        name: analog.name,
                        sum: analog.sum,
                        data: analog.data,
                        color: analog.color,
                        openSettings: false,
                        topPosition: null,
                    }
                })
            }

            // check mat balance
            if (caseData.mat_balance) {
                state.matBalance = caseData.mat_balance.map(analog => {
                    return {
                        isActive: analog.item.active,
                        year: analog.item.offset,
                        name: analog.item.name,
                        sum: analog.item.sum,
                        data: analog.item.data,
                        color: analog.item.color,
                        settings: {
                            min: Number(state.caseSettings.start_year) - 100,
                            max: Number(state.caseSettings.start_year) + 100,
                            step: 1
                        },
                        openSettings: false,
                        topPosition: null,
                        params: analog.params
                    }
                })
            }

            // check WC
            if (caseData.wc) {
                state.WC.cases = caseData.wc.cases && caseData.wc.cases.length ? caseData.wc.cases : []
                state.WC.models = caseData.wc.models && caseData.wc.models.length ? caseData.wc.models : []
            }

            this.commit('common/setLoadingStatus', false)
        },
        async updatePropertyState({ state }, payload) {
            const propId = payload.id,
                data = payload.data,
                activeWellTypeUuid = payload.activeWellTypeUuid


            state.wellTypes.find(item => item.uuid === activeWellTypeUuid).data[propId] = {
                ...data,
               distribution: data.distribution.value
            }

            return true
        },
        async updatePropertyQuantityState({ state }, payload) {
            const propId = payload.id,
                data = payload.data

            state.caseSettings[propId] = {
                ...data,
                distribution: data.distribution.value
            }

            return true
        },
        async updateDeclineType({ state }, payload) {
            const value = payload.value
            const activeWellTypeUuid = payload.activeWellTypeUuid

            state.wellTypes.find(item => item.uuid == activeWellTypeUuid).data.decline_type = value

            return true
        },
        async updateCaseSettings({ state }, payload) {
            const data = payload.data

            for (let key in data) {
                state.caseSettings[key] = data[key].value
            }

            return true
        },
        async updateWellSettings({ state }, payload) {
            const data = payload.data
            const activeWellTypeUuid = state.caseSettings.active_well_type_uuid
            const well = state.wellTypes.find(item => item.uuid == activeWellTypeUuid)

            for (let key in data) {
                well.data[key] = data[key].value
                if (key === 'density') {
                    state.wellTypes.forEach(type => {
                        if (type.uuid !== activeWellTypeUuid) {
                            type.data.density = data[key].value
                        }
                    })
                }
            }

            return true
        },
        async getChart({ state } , payload) {
            const wellUuid = payload

            const { data, pending, error, refresh } = await useFetch(`/api/welltypes/generate-chart`, {
                method: 'POST',
                body: JSON.stringify({
                    wells: {
                        [wellUuid]: state.wellTypes.find(item => item.uuid === wellUuid).data,
                    },
                    engineer_case_id: state.options.caseData.caseId,
                    engineer_case_type: state.options.caseTypes.find(item => item.isActive).id,
                    result_quantity: state.caseSettings.result_quantity
                })
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                useNuxtApp().$engineerCaseError(errorMessage.message, new Error())

                return
            }

            useGtag().gtag('event', 'start_calculation', {
				'case_type': 'production' 
			})

            return data.value
        },
        async listenWellJob({ state }, payload) {
            const { jobId, type } = payload

            return new Promise((resolve, reject) => {
                this.commit('common/blockInterface', {
                    isActive: true,
                    title: `Calculating well "${type.name}"`
                });
                Echo.channel(`job_${jobId}`).listen('.change', (e) => {
                    this.commit('common/blockInterface', {
                        isActive: true,
                        progress: e.job.progress
                    });
                    if (e.job.status === 2) {
                        $fetch(`/api/jobs/${e.job.id}`).then(response => {
                            this.commit('engineering/saveChartData', [type.uuid, response.result, _.cloneDeep(type.data), 'well_model', jobId]);
                            resolve()
                        }).catch(error => {
                            this.commit('common/blockInterface', {
                                isActive: false
                            })
                        });
                    };
                    if (e.job.status === 3) {
                        this.commit('common/setErrorData',
                            {
                                statusCode: e.job.status,
                                message: e.job.metadata.error,
                                showError: true
                            }
                        );
                        this.commit('common/blockInterface', {
                            isActive: false
                        });
                        reject()
                    };
                });
            })
        },
        async getChartResult({ state }) {
            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/engineercases/generate-chart`, {
                method: 'POST',
                body: JSON.stringify({
                    case: {
                        ...state.caseSettings,
                        ...(state.selectedCase ? {
                            geo_case_id: state.selectedCase.info.id,
                            geo_case_type: state.selectedCase.info.model
                        } : {}),
                        engineer_case_id: state.options.caseData.caseId,
                        engineer_case_type: state.options.caseTypes.find(item => item.isActive).id,
                        wc: state.WC
                    },
                    wells: state.wellTypes.reduce((cur, type) => { return Object.assign(cur, {
                        [type.uuid]: type.data
                    })}, {})
                })
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                useNuxtApp().$engineerCaseError(errorMessage.message, new Error())

                return
            }

            useGtag().gtag('event', 'start_calculation', {
				'case_type': 'production' 
			})

            if (typeof ym !== 'undefined') {
                ym(useRuntimeConfig().public.YM_ID,'reachGoal','start_calculation')
            }

            try {
                const { wells_results: wellsResults, wells_job_ids: wellsJobs, ...objectResult } = data.value

                if (wellsResults) {
                    for (let uuid of Object.keys(wellsResults)) {
                        const wellType = state.wellTypes.find(type => type.uuid === uuid)
                        this.commit('engineering/saveChartData', [uuid, wellsResults[uuid], _.cloneDeep(wellType.data), 'well_model', null])
                    }
                }

                if (wellsJobs) {
                    for (let uuid of Object.keys(wellsJobs)) {
                        const wellType = state.wellTypes.find(type => type.uuid === uuid)
                        await this.dispatch('engineering/listenWellJob', {
                            type: wellType, 
                            jobId: wellsJobs[uuid]
                        })
                    }
                }

                return objectResult
            } catch (e) {
                useNuxtApp().$engineerCaseError('Error getting result', e)
            }
        },
        async getCaseDistribution({ state } , payload) {
            try {
                const response = await $fetch(`/api/projects/${state.options.caseData.projectId}/engineercases/tuning`, {
                    method: 'POST',
                    body: {
                        wells: state.wellTypes.map(type => ({...type.data, uuid: type.uuid })),
                        case: {
                            ...state.caseSettings,
                            ...(state.selectedCase ? {
                                geo_case_id: state.selectedCase.info.id,
                                geo_case_type: state.selectedCase.info.model
                            } : {}),
                            wc: state.WC
                        },
                        engineer_case_id: state.options.caseData.caseId,
                        engineer_case_type: state.options.caseTypes.find(item => item.isActive).id
                    }
                })
    
                return response

            } catch (e) {
                console.log(e)
            }
        },
        async parseWellAnalogs({ state }, payload) {
            const { name, file } = payload
            let form = new FormData()

            form.append('file_input', file)

            try {
                const wellData = await $fetch(
                    `/api/projects/${state.options.caseData.projectId}/engineercases/parse_well_prod`, {
                        method: 'POST',
                        body: form
                    }
                )

                state.wellAnalogs.push({
                    name: name,
                    ...wellData,
                    isActive: true,
                    settings: {
                        min: -100,
                        max: 100,
                        step: 1
                    },
                    year: 0,
                    color: '#FF9E5C',
                    openSettings: false,
                    topPosition: null
                })
            } catch(e) {
                throw new Error(e)
            }
        },
        async parseWCCase({ state }, payload) {
            const { name, file } = payload
            let form = new FormData()

            form.append('file_input', file)

            const { data, pending, error, refresh } = await useFetch(`/api/projects/${state.options.caseData.projectId}/engineercases/parse_for_calc_water`, {
                method: 'POST',
                body: form
            })
        
            if (error.value) {
                const errorMessage = error.value.data
        
                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })
                
                return false
            }

            state.WC.cases.push({
                name: name,
                data: data.value,
                isActive: false,
                checked: true,
                color: '#FF9E5C'
            })

            return true
        },
        async addWCModel({ state }, payload) {
            state.WC.models.push(payload)
        },
        async getParamsTable({ state } , param) {
            const responseFile = await $fetch(`/api/projects/${state.options.caseData.projectId}/engineercases/export/${param}_data`, {
                method: 'POST',
                body: {
                    case: {
                        ...state.caseSettings,
                        ...(state.selectedCase ? {
                            geo_case_id: state.selectedCase.info.id,
                            geo_case_type: state.selectedCase.info.model
                        } : {}),
                        engineer_case_id: state.options.caseData.caseId,
                        engineer_case_type: state.options.caseTypes.find(item => item.isActive).id
                    },
                    wells: state.wellTypes.reduce((cur, type) => { return Object.assign(cur, {
                        [type.uuid]: type.data
                    })}, {})
                }
            })

            return responseFile
        },
    }
}